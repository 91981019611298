import React, { useState, useEffect } from 'react';
import * as Util from '../data/General';
import WhatsApp from '../components/html/WhatsApp';
import Button from '../components/html/Button';
import CreateWhatsApp from '../components/html/CreateWhatsApp';
import Tabs from '../components/html/Tabs';
import InputHtml from '../components/html/InputHtml';
import SelectHtml from '../components/html/SelectHtml';


function ContentSidIA(props) {
    const { setBody } = props;
    const [loader, setLoader] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [config, setConfig] = useState(null);
    const [title, setTitle] = useState("");
    const [sub_title, setSub_title] = useState("");
    const [valide, setValide] = useState(true);
    const [query, setQuery] = useState("");
    const [extra, setExtra] = useState("");
    const [type, setType] = useState(true);
    const [infoExtra, setInfoExtra] = useState("");
    
    let shopJson =  Util.getAdminShop();
    
    
    const setFlow = (flow, index) => {
        let name = flow.name;
        setType(name);
        if("Carritos_abandonados" === name){//Carrito abandonados
            setLoader(true);
            Util.getBodyWhatsAppPHP(name, shopJson.name, isOKGenerate, isErrorGenerate);
        }else if("Limited_offer" === name || "Promote_product" === name || "Personalized" === name || "repeat_customers" === name){//Oferta por tiempo limitado
            setShowInfo(true);
            setConfig(Util.CONTENTSIDIA[index].config);
            setTitle(Util.CONTENTSIDIA[index].title);
            setSub_title(Util.CONTENTSIDIA[index].sub_title);
            setQuery(shopJson.name + Util.getMessage(Util.CONTENTSIDIA[index].query));
        }
    }
  
    const isOKGenerate = (data, type, extra) => {
      console.log("data", data);
      if(data && data.data){
        if("Promote_product" === type){//Promocionar producto
            extra = {
                text: extra,
                image: infoExtra
            }
        }
        setBody(data.data, type, extra);
        setLoader(false);
        setShowInfo(false);
        }
    }
  
    const isErrorGenerate = (data) => {
      console.log("data", data);
    }
    const cancel = (data) => {
        setShowInfo(false);
      }
    
    useEffect(() => {

    },[extra]);

    const onChange = (value, name) => {
        config[name].value = value;
        setConfig(config);
        let extra = convert(query, config);
        setExtra(extra);
      }
    
      const onChangeValuesIn = (value, name) => {
        
      }
    
      const onChangeLP = (id, nameProduct, image, name) => {
        config[name].value = nameProduct;
        config[name].image = image;
        setInfoExtra(image);
        config[name].valueId = id;
        setConfig(config);
        let extra = convert(query, config);
        setExtra(extra);
      }
    
    const newFlow = () => {

        let extra = convert(query, config);
        setLoader(true);
        Util.getBodyWhatsAppPHP(type, extra, isOKGenerate, isErrorGenerate);

    }


      const convert = (text, config, isText=false, isInic=false) => {
        {config && Object.keys(config).map((key, index) => {
            let inic = (config[key].inic ? config[key].inic : "");
            let finish = (config[key].finish ? config[key].finish : "");
            if(isInic){
                inic="";finish="";
            }
            text = text.replace(`[${config[key].referece ? config[key].referece : key}]`, inic + ((isText && config[key].convertValue && config[key].valueName) ? config[key].valueName : config[key].value) + finish);
            {config[key].aditional && Object.keys(config[key].aditional).map((keyA, indexA) => {
                text = text.replace(`[${keyA}]`, inic + (config[key].value + config[key].aditional[keyA])+finish);
            })}
            {config[key].subtract && Object.keys(config[key].subtract).map((keyA, indexA) => {
                text = text.replace(`[${keyA}]`, inic + (config[key].value - config[key].subtract[keyA])+finish);
            })}
        })}
    
        return text;
      }
       
      
    if(loader){
        return (
            <div className="presentacion" >
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }else if(showInfo){
        return (
            <div className="create-flow content-reviews background-style">
                <div className="title content-flex space-between space-top">{Util.getMessage(title)}</div>
                <label>{Util.getMessage(sub_title)}</label>
                {config && Object.keys(config).map((key, index) =>
                    <div key={index} className='text'>
                        {Util.getMessage(config[key].label)}
                        {config[key].type === "input" ? (
                            <InputHtml className="" edit={true} placeholder={config[key].sub_type === "listproduct" ? Util.getMessage(config[key].postfix) : ""} type={config[key].sub_type} name={key} value={config[key].value} onBlur={onChange} onBlurListProductImage={onChangeLP} min={config[key].min} max={config[key].max} step={config[key].step}/>
                        ) : (config[key].type === "select" && config[key].sub_type && config[key].sub_type === "valuesIn") ? (
                            <SelectHtml className="" type="datetime-local" edit={true} name={key} value={config[key].value} onChange={onChangeValuesIn} isKey={false} valuesIn={config[key].options} defaultText={Util.getMessage(config[key].defaultText)} defaultValue=""/>
                        ) : config[key].type === "select" ? (
                            <SelectHtml className="" type="datetime-local" edit={true} name={key} value={config[key].value} onChange={onChange} isKey={false} simpleValues={config[key].options}/>
                        ) : null } 
                    </div>
                )}
                <div className='button_campaign'>
                    <Button value={Util.getMessage("btn_continue")} disabled={!valide} type="primary" onClick={() => newFlow()}/>
                    <Button value={Util.getMessage("Volver")} onClick={() => {cancel()}}/>
                </div>
            </div>
        );
    }else if(shopJson){
        return (
            <div>
                <div className='content-flow content-reviews'>
                <div className='title content-flex space-between space-top'>
                    {Util.getMessage("csia_title")} 
                </div>
                <label>{Util.getMessage("csia_text")}</label>
                <div className='select-flow'>
                {Util.CONTENTSIDIA && Util.CONTENTSIDIA.map((value, index) =>
                    <article key={index} onClick={() => setFlow(value, index)}>
                        <h2>{Util.getMessage(value.title)}</h2>
                        <p>{Util.getMessage(value.description)}</p>
                        <div className='content-flex space-between'>
                            <span></span>
                            <img src='/img/lw.png' alt="WhatsApp" />
                        </div>
                    </article>
                )}
                </div>
            </div>
            </div>
        );
    }else{
        return (<div></div>);
    }
}
    
export default ContentSidIA;
