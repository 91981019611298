/**
 * ,<{
            url: "/campaigns-check-hearings",
            title: "hearings",
        }
 */
export default[
    {
        "url": "/home",
        logo: 'img/menu/home.png',
        logo_active: 'img/menu/home_active.png',
        title: "dashboard"
    },{
        "url": "/reviews",
        logo: 'img/menu/reviews.png',
        logo_active: 'img/menu/reviews_active.png',
        title: "reviews"
    },{
        "url": "/plan",
        logo: 'img/menu/plan.png',
        logo_active: 'img/menu/plan_active.png',
        title: "plan"
    },{
        url: "/campaigns-check",
        logo: 'img/menu/marketing.png',
        logo_active: 'img/menu/marketing_active.png',
        title: "marketing",
        shopType: "SHOPIFY",
        submenu:[{
            url: "/campaigns-check-normal",
            title: "campaigns",
            submenu:[{
                url: "/campaigns-check-normal-hearings",
                title: "hearings",
            }]
        },{
            url: "/campaigns-check-flow",
            title: "flow",
        }]
    },{
        "url": "/analytics",
        logo: 'img/menu/analytics.png',
        logo_active: 'img/menu/analytics_active.png',
        title: "analytic",
        submenu:[{
            "url": "/analytics-products",
            title: "products",
        }]
    },{
        "url": "/config",
        logo: 'img/menu/config.png',
        logo_active: 'img/menu/config_active.png',
        title: "configuracion",
        submenu:[{
            "url": "/config-whatsapp",
            title: "confg_whatsapp",
            id: "55229055169",
            planType:"PLUSNOOO"
        }]
    },{
        "url": "/frequentquestions",
        logo: 'img/menu/freq.png',
        logo_active: 'img/menu/freq_active.png',
        title: "frequentquestions"
    }
    ];
    