import React, { Component } from 'react';
import { connect } from 'react-redux';
import WhatsApp from './WhatsApp';
import InputHtml from './InputHtml';
import Button from './Button';
import Notify from './Notify';
import mixpanel from 'mixpanel-browser';
import * as Constants from './../../store/index';

import axios from "axios";

import * as Util from './../../data/General';
import TextAreaHtml from './TextAreaHtml';
import UploadImages from './UploadImages';

class CreateWhatsApp extends Component {


    state = {
      error: null,
      wa: null,
      notify: "",
      isShowUrl: true,
      isShopAdmin: false,
      subtype: 0,
      loading: false
    };

    
    componentDidMount() {
      let shopJson = Util.getAdminShop();
      let isShopAdmin =  false;

      let wa = {
        "sid": "",
        "mediaurl": "",
        "mediatext": "",
        "footer": Util.getMessage("wht_stop"),
        "body": "",
        "url": "",
        "buttontext": "",
        "websiteurl": "",
        "name": "",
        "templatename": "",
        "marketingCredential": {
          "twilioUsername": "",
          "twilioPassword": "",
          "messagingService": ""
        }, 
        isLoad: false
      }
      if(shopJson){
          this.setState({shop: shopJson});
          mixpanel.init(window.mixpanelKey); 
          mixpanel.track('CREATE TEMPLATE WHATSAPP', {
              'shopDomain': shopJson.domain,
              'shopId': shopJson.id,
          });

          if(shopJson.id === Util.SHOP_ADMIN_ID){
            isShopAdmin = true;
          }
      }
      let { setUrl=null, editTemplate, waIA } = this.props;
      if(setUrl){
        wa.websiteurl = setUrl;
        this.setState({wa: wa, isShowUrl: false});
      }
      if(waIA){
        wa.body = waIA.body;
        wa.mediaurl = waIA.mediaurl;
        wa.name = waIA.name;
      }

      if(editTemplate){
        editTemplate.sid = "";
        if(editTemplate.url === ""){
          editTemplate.url = Util.getShopDomain();
        }
        this.setState({isShopAdmin: isShopAdmin, wa: editTemplate});
      }else{
        this.setState({isShopAdmin: isShopAdmin, wa: wa});
      }
      
    }

    onBlurCampaing = (value, name) => {
      const { wa } = this.state;
      if(name === "websiteurl" && value.startsWith('http')){
        this.isError("error_whastsapp_6")
      }
      wa[name] = value; 
      this.setState({wa: wa});
    }

    isError = (errorNew) => {
      const { error } = this.state;
      if(error !== errorNew){
        this.setState({error: errorNew});
      }
    }
    newCampaigns = (example=false) =>{
        const { wa, subtype } = this.state;
        const { typeTemplate } = this.props;
        this.setState({isLoad: true});
        
        const url = window.url_server_2+"setcontent/?shop="+Util.getShopDomain();
        wa["accessTokenStore"] = Constants.getAccessTokenStore();
        wa["type"]= typeTemplate;
        if(example){
          wa["type"] = Util.EXAMPLE_TAB;
          wa["subtype"] = subtype;
          wa["url"] = "";
          wa["websiteurl"] = "";
        }
        axios.post(url, wa, {}).then(response => response.data,error => {
            return error;
          }).then((data) => {
            if (data && data.code === "200"){
              //let contentSid = data.data;
              //console.log(contentSid);
              const { oncreate } = this.props;
              this.setState({isLoad: false});
              if(oncreate){
                oncreate();
              }
            }else{
              alert("Ocurrio un error al crear la plantilla, comuniquese con Revie!");
            }
        });
    
    }

    generateIA = () => {
      let { waIA } = this.props;
      const { wa } = this.state;
      wa["body"] = "";
      console.log("generateIA");
      if(waIA){
        console.log(waIA);
        this.setState({wa: wa,loading: true});
        Util.getBodyWhatsAppPHP(waIA.type, waIA.extra, this.isOKGenerate, this.isErrorGenerate);
      }else{
        let shopJson = Util.getAdminShop();
        this.setState({wa: wa,loading: true});
        let extra = shopJson.name + Util.getMessage("generic.query");
        Util.getBodyWhatsAppPHP("generic", extra, this.isOKGenerate, this.isErrorGenerate);
      }
    }

    isOKGenerate = (data, type, extra) => {
      console.log("data", data);
      const { wa } = this.state;
      if(data && data.data){
        wa["body"] = data.data;
        this.setState({wa: wa, loading:false});
      }
    }
  
    isErrorGenerate = (data) => {
      console.log("data", data);
    }

    render() {

      const { wa, notify, isLoad, error, isShowUrl, isShopAdmin, subtype, loading } = this.state;
      if (!wa) return null;
      return (
        <div className="content-reviews crate-wa">
          <div className='content-flex space-between'>
            <div className='content-config'>
              <div className='text'>
                <h2>{Util.getMessage("form_1")}</h2>
                <InputHtml className="" placeholder="30OFF discount" edit={true} isOnlyTL={true} name="name" value={wa.name} onBlur={this.onBlurCampaing}/>
              </div>
              <div className='text' style={{"position": "relative"}}>
                <h2>{Util.getMessage("form_2")}</h2>
                {loading ? (
                  <div className="presentacion" >
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  </div>
                  ) : (
                    <TextAreaHtml addButton={true} className="" loading={loading} edit={true} name="body" value={wa.body} onBlur={this.onBlurCampaing} generateIA={this.generateIA}
                  small={Util.getMessage("create_whatsApp_help_body")}/>
                  )
                }
                
              </div>
              <div className='text'>
                <h2>{Util.getMessage("form_3_bis")}</h2>
                <div style={{display: "none"}}><InputHtml className="" disabled={wa.mediatext !== ""} placeholder="https://" name="mediaurl" value={wa.mediaurl} onBlur={this.onBlurCampaing} 
                  small={Util.getMessage("create_whatsApp_help_mediaurl")}/></div>

                <UploadImages onBlur={this.onBlurCampaing} name="mediaurl" value={wa.mediaurl} />  
              </div>
              <div className='text'>
                <h2>{Util.getMessage("form_4")}</h2>
                <InputHtml className="" disabled={wa.mediaurl !== ""} placeholder="" name="mediatext" value={wa.mediatext} onBlur={this.onBlurCampaing} 
                  small={Util.getMessage("create_whatsApp_help_mediatext")}/>
              </div>
              <div className='text'>
                <h2>{Util.getMessage("form_5")}</h2>
                <InputHtml className="" placeholder="Type button text" name="buttontext" value={wa.buttontext} onBlur={this.onBlurCampaing}/>
              </div>
              {isShowUrl ? (
                <div className='text'>
                  <h2>{Util.getMessage("form_6")}</h2>
                  <InputHtml className="" placeholder="https://" name="websiteurl" value={wa.websiteurl} onBlur={this.onBlurCampaing} small={Util.getMessage("create_whatsApp_help_websiteurl")}/>
                </div>
              ) : null}
              {error && <div className="error" >{Util.getMessage(error)}</div>}
                
              <div className='text button_campaign'>
                <Button value={Util.getMessage("btn_create")} disabled={error !== null || wa.name === "" || wa.body === "" || wa.buttontext === "" || wa.websiteurl === ""} loader={isLoad} type="primary" onClick={() => this.newCampaigns()}/>

                {isShopAdmin ? (
                  <>
                    <select value={subtype} onChange={(e) => this.setState({subtype: e.target.value})}>
                      {Util.LISTSUBTYPE && Util.LISTSUBTYPE.map((item2, key2) => (
                          <option key={key2} value={item2}>{Util.getMessage(item2)}</option> 
                      ))}
                    </select>
                    <Button value={"Crear plantilla de ejemplo"} disabled={error !== null || wa.name === "" || wa.body === "" || wa.buttontext === "" } loader={isLoad} type="primary" onClick={() => this.newCampaigns(true)}/>
                  </>
                  
                ): null}
              </div>
              
            </div>
            <div className='cont-wp'>
              <WhatsApp message={wa} isError={this.isError}/>
            </div>
          </div>
          {notify !== "" && (
              <Notify text={notify} />
          )}
        </div>
        );
        }
      }
    
const mapStateToProps = (state) => ({
    shop: state.shop,
    filter: state.filter
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWhatsApp);
