import React, { Component } from 'react';
import { connect } from 'react-redux';
import CheckboxTemplate from '../components/html/CheckboxTemplate';
import SelectHtml from '../components/html/SelectHtml';
import InputHtml from '../components/html/InputHtml';
import * as Util from './../data/General';
import * as Constants from './../store/index';

import mixpanel from 'mixpanel-browser';
import SelectStar from '../components/html/SelectStar';
import Button from '../components/html/Button';
import Notify from '../components/html/Notify';
import Tabs from '../components/widget/Tabs';

class Config extends Component {

    state = { 
        templates: {
            TEMPLATE_1: {
                name: "temp1",
                menssagetitle1: "temp1_title1",
                menssagetitle2: "temp1_title2",
                menssagetext1: "temp1_text1",
                menssagetext2: "temp1_text2",
                id: "TEMPLATE_1",
                plan:{},
                planActive: "0"
            }, 
            TEMPLATE_2: {
                name: "temp2",
                menssagetitle1: "temp2_title1",
                menssagetitle2: "temp2_title2",
                menssagetext1: "temp2_text1",
                menssagetext2: "temp2_text2",
                id: "TEMPLATE_2",
                plan:{"50":true, "200":true, "0":true, "FREE": true, "LITE": true},
                planActive: "PRO"
            }, 
            TEMPLATE_CUSTOM: {
                isCustom: true,
                name: "tempCustom",
                menssagetitle1: "temp2_title1",
                menssagetitle2: "temp2_title2",
                menssagetext1: "temp2_text1",
                menssagetext2: "temp2_text2",
                id: "TEMPLATE_CUSTOM",
                plan:{"50":true, "200":true, "500":true, "0":true, "1000":true, "FREE": true, "LITE": true, "PRO": true},
                planActive: "PLUS"
            }, 
        },
        permissions:{
            reciveAudio: {"50":true, "0":true, "FREE": true},
            reciveImages: {"50":true, "0":true, "FREE": true},
            reciveVideos: {"50":true, "0":true, "FREE": true},
            discount: {"50":true, "0":true, "200":true, "FREE": true, "LITE": true},
            windowDaysReviews: {"50":true, "0":true, "FREE": true, "LITE": true},
            autoPublishStar: {"50":true, "0":true, "FREE": true, "LITE": true},
            import: {"50":true, "0":true, "FREE": true},
        },
        configView: [
            {
                id: "1",
                textActive: "SI", 
                textInActive: "NO", 
                text:"options_checkbox_1",
                selected: false
            },
            {
                id: "1",
                textActive: "SI", 
                textInActive: "NO", 
                text:"options_checkbox_2",
                selected: true
            },
            {
                id: "1",
                textActive: "SI", 
                textInActive: "NO", 
                text:"options_checkbox_3",
                selected: true
            },
            {
                id: "1",
                textActive: "SI", 
                textInActive: "NO", 
                text:"options_checkbox_4",
                selected: true
            },
            {
                id: "1",
                textActive: "SI", 
                textInActive: "NO", 
                text:"options_checkbox_5",
                selected: true
            },
        ],
        shop: null,
        shopPHP: null,
        isShowExport: false,
        notify: '',
        loading: false,
        enabledPriceRules: true,
        totalDaysInstall: 0,
        addScript: true,
        widgetCode: false,
        storeTN:null,
        configTN:[
            {
                "title": "config_tn_title_1",
                "text": "config_tn_text_1",
                "name": "position-stars",
                "default": "product-price-*ID*",
                "values": [
                    {
                        "label": "start_label_1",
                        "value": "product-name-*ID*"
                    },{
                        "label": "start_label_2",
                        "value": "product-price-*ID*"
                    },{
                        "label": "start_label_3",
                        "value": "product-form-*ID*"
                    },{
                        "label": "no_show",
                        "value": ""
                    }
                ]
            },{
                "title": "config_tn_title_2",
                "text": "config_tn_text_2",
                "name": "position-resume",
                "default": "product-description-*ID*",
                "values": [
                    {
                        "label": "datail_label_1",
                        "value": "product-description-*ID*"
                    },{
                        "label": "datail_label_2",
                        "value": "related-products"
                    },{
                        "label": "datail_label_3",
                        "value": "product-detail"
                    },{
                        "label": "no_show",
                        "value": ""
                    }
                ]
            },{
                "title": "config_tn_title_3",
                "text": "config_tn_text_3",
                "name": "position-category",
                "default": "product-item-price-*ID*",
                "values": [
                    {
                        "label": "category_label_1",
                        "value": "product-item-name-*ID*"
                    },{
                        "label": "category_label_2",
                        "value": "product-item-price-*ID*"
                    },{
                        "label": "category_label_3",
                        "value": "product-item-image-*ID*"
                    },{
                        "label": "no_show",
                        "value": ""
                    }
                ]
            }
        ],
        configTNWidgets:[
            {
                "title": "config_tn_widget_title_1",
                "text": "config_tn_widget_text_1",
                "name": "position-widget-home",
                "default": "",
                "values": [
                    {
                        "label": "no_show",
                        "value": ""
                    },{
                        "label": "wigdet_home_label_1",
                        "value": "home-slider"
                    },{
                        "label": "wigdet_home_label_2",
                        "value": "home-banner-categories",
                        "not": "Lifestyle"
                    },{
                        "label": "wigdet_home_label_3",
                        "value": "home-banner-promotional",
                        "not": "Idea-Atlantico-Base"
                    },{
                        "label": "wigdet_home_label_4",
                        "value": "home-banner-news",
                        "in": "Rio-Lima-Cali-Uyuni-Toluca"
                    },{
                        "label": "wigdet_home_label_5",
                        "value": "home-banner-featured",
                        "in": "Toluca"
                    },{
                        "label": "wigdet_home_label_6",
                        "value": "home-banner-with-text",
                        "in": "Lifestyle"
                    },{
                        "label": "wigdet_home_label_7",
                        "value": "home-banner-with-image",
                        "in": "Silent"
                    },{
                        "label": "wigdet_home_label_8",
                        "value": "home-banner-with-image",
                        "in": "Simple-Material"
                    },{
                        "label": "wigdet_home_label_9",
                        "value": "home-image-text-module",
                        "in": "Simple-Trend-Cali-Morelia-Cubo-Bahia-Lifestyle-Base"
                    },{
                        "label": "wigdet_home_label_10",
                        "value": "home-categories-featured",
                        "in": "Lima-Cali-Uyuni-Toluca-Morelia-Simple-Trend-Lifestyle-Silent-Material"
                    },{
                        "label": "wigdet_home_label_10",
                        "value": "banner-home-categories",
                        "in": "Rio"
                    },{
                        "label": "wigdet_home_label_12",
                        "value": "home-welcome-message",
                        "in": "Amazonas-Idea-Lima-Cali-Uyuni-Toluca-Morelia"
                    },{
                        "label": "wigdet_home_label_13",
                        "value": "home-institutional-message",
                        "in": "Simple-Cali-Uyuni-Toluca-Morelia-Lifestyle-Silent-Base"
                    },{
                        "label": "wigdet_home_label_14",
                        "value": "home-products-featured",
                        "not": "Rio"
                    },{
                        "label": "wigdet_home_label_14",
                        "value": "products-home-featured",
                        "in": "Rio"
                    },{
                        "label": "wigdet_home_label_15",
                        "value": "home-products-new",
                        "in": "Amazonas-Rio-Lima-Cali-Uyuni-Toluca-Morelia-Cubo-Lifestyle-Bahia"
                    },{
                        "label": "wigdet_home_label_16",
                        "value": "home-products-sale",
                        "in": "Amazonas-Simple-Rio-Lima-Cali-Uyuni-Toluca-Morelia-Cubo-Lifestyle-Bahia-Silent-Atlantico"
                    },{
                        "label": "wigdet_home_label_17",
                        "value": "home-products-promotion",
                        "in": "Toluca-Morelia"
                    },{
                        "label": "wigdet_home_label_18",
                        "value": "home-products-best-seller",
                        "in": "Toluca"
                    },{
                        "label": "wigdet_home_label_19",
                        "value": "home-products-coming-soon",
                        "in": "Toluca-Lifestyle"
                    },{
                        "label": "wigdet_home_label_20",
                        "value": "home-products-recent",
                        "in": "Lifestyle"
                    },{
                        "label": "wigdet_home_label_21",
                        "value": "home-products-slider",
                        "in": "Material-Idea"
                    },{
                        "label": "wigdet_home_label_22",
                        "value": "home-video"
                    },{
                        "label": "wigdet_home_label_23",
                        "value": "home-brands",
                        "in": "Amazonas-Atlantico-Lima-Cali-Uyuni-Toluca-Morelia"
                    },{
                        "label": "wigdet_home_label_24",
                        "value": "home-testimonials",
                        "in": "Lima-Cali-Uyuni-Toluca-Morelia"
                    }
                ]
            },{
                "title": "config_tn_title_4",
                "text": "config_tn_text_4",
                "name": "position-widget-product",
                "default": "",
                "values": [
                    {
                        "label": "datail_label_1",
                        "value": "product-description-*ID*"
                    },{
                        "label": "datail_label_2",
                        "value": "related-products"
                    },{
                        "label": "no_show",
                        "value": ""
                    }
                ]
            },{
                "title": "config_tn_title_5",
                "text": "config_tn_text_5",
                "name": "GC",
                "default": "",
                "values": [],
                "isCustom": true
            }
        ]
    };

    componentDidMount() {
        let install = localStorage.getItem(Constants.storage.USER_INSTALL);
        if (install && install === "true"){
            setTimeout(function(){
                localStorage.setItem(Constants.storage.USER_INSTALL, false);
            }.bind(this), 2000);
        }

        let shopJson =  Util.getAdminShop();
        if(shopJson){
            let totalDaysInstall = this.checkDateShop(shopJson);
            this.setState({shop: shopJson, totalDaysInstall: totalDaysInstall});
            mixpanel.init(window.mixpanelKey); 
            mixpanel.track('Onboarding', {
            'shopDomain': shopJson.domain,
            'shopId': shopJson.id,
            });
            Util.getInfoShopAdminPHP(shopJson.lang, this.callbackPHP, this.callbackErrorPHP);
            Util.getInfoShop(this.handleOkShopPlan, this.handleOkShopError);
            if(shopJson.shopType === "TIENDANUBE"){
                Util.getStorePHP(shopJson, this.callbackStore, this.callbackErrorPHP);
            }
            
        }
        
    }

    callbackStore = (data) => {
        if(data){
            let config = JSON.parse(data);
            console.log("Data",config);
            this.setState({storeTN: config});
        }
    }

    checkDateShop = (shop) => {
        const d = new Date();
        let myArray = shop.creationDate.split("T");
        let creationDate = new Date(myArray[0]);
        let date_hoy = new Date();
        let difference = date_hoy.getTime() - creationDate.getTime();
        let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return totalDays;
      }

    handleOkShopPlan = (data) => {
        console.log(data);
        if (data && data.id){
            this.setState({shop: data});
            localStorage.setItem(Constants.storage.USER_SHOP, JSON.stringify(data));
        }
      }
    
      handleOkShopError = (data) => {
        console.log(data);
      }

    callbackPHP = (data) => {
        if(data && data.data){
            let config = JSON.parse(data.data);
            data.data = config;
            console.log("Data",data);
            this.setState({shopPHP: data});
        }
    }

    callbackErrorPHP = (data) => {
        console.log("ERROR Data",data);
    }

    onChangePhoto = (value, name) => {
        const { shop } = this.state;
        if (shop && shop.plan && shop.plan.planType !== "50"){
            this.onChange(value, name);
        }
    }

    onChange1000 = (value, name) => {
        const { shop } = this.state;
        if (shop && shop.plan && shop.plan.totalReview >= 1000){
            this.onChange(value, name);
        }
    }

    onChangeDiscount = (value, name) => {
        const { shop } = this.state;
        console.log("shop",shop);
        if (shop && shop.plan && shop.plan.planType !== "50" && shop.plan.planType !== "200"){
            if(Util.isShopify(shop) && name === "active" && value === "true"){
                Util.getDiscount(this.handleOkDiscount, this.handleOkDiscountError);
            }else{
                this.onChange(value, name, "discount");
            }
        }
    }

    handleOkDiscount = (data) => {
        if (data && data.enabled){
            this.onChange("true", "active", "discount");
        }else{
            this.setState({enabledPriceRules: false});
        }
    }
    handleOkDiscountError = (data) => {
        console.log(data);
        this.setState({enabledPriceRules: false});       
    }

    onChange = (value, name, aditional=null) => {
        const { shop } = this.state;
        if (aditional){
            shop[aditional][name] = (value !== "" ? value : null); 
            if (shop[aditional][name] === "false"){
                shop[aditional][name] = false; 
            }

            if (shop[aditional]["valueType"] !== "FIXED_AMOUNT"){
                shop[aditional]["valueType"]="PERCENTAGE";
            }
        }else{
            shop[name] = (value !== "" ? value : null); 
            if (shop[name] === "false"){
                shop[name] = false; 
            }
            if (name === "reciveVideos" && value === "true" && (shop["reciveImages"] === "false" || shop["reciveImages"] === false)){
                shop["reciveImages"] = true;
            }

            if (!shop["reciveImages"] && !shop["reciveVideos"] && (shop["discount"]["active"] === true)){
                shop["discount"]["active"] = false;
            }
            if (name === "windowDaysReviews" && value === "-1"){
                shop["validateProductReviews"] = false;
            }
        }
        console.log("shop",shop);
        this.setState({shop: shop});
        Util.setInfoShop(this.handleOkShop, this.handleOkShoperror, shop);

        if("lang" === name){
            Util.setLanguage(shop, true);
        }
    }

    onChangeStar = () => {
        const { shop } = this.state;
        if (shop && shop.plan && shop.plan.planType !== "50" && shop.plan.planType !== "0"){
            if (!shop.autoPublishStar){
                shop.autoPublishStar = 5;
            }else{
                shop.autoPublishStar = null;
            }
            
            this.setState({shop: shop});
            Util.setInfoShop(this.handleOkShop, this.handleOkShoperror, shop);
        }

    }

    onChangeStarNumber = (value) => {
        const { shop } = this.state;

        shop.autoPublishStar = value;
        
        this.setState({shop: shop});
        Util.setInfoShop(this.handleOkShop, this.handleOkShoperror, shop);

    }

    onBlur = (value, name) => {
        const { shop } = this.state;
        shop[name] = value; 
        console.log(shop);
        this.setState({shop: shop});
    }

    onBlurDiscount = (value, name) => {
        const { shop } = this.state;
        shop["discount"][name] = value; 
        console.log(shop);
        this.setState({shop: shop});
    }

    onUpConf  = (value, name) => {
        if(name === "position-widget-product" || name === "position-widget-home"){
            const { shopPHP, shop } = this.state;
            if(shopPHP){
                let codeHtml = "";
                console.log(value);
                if(value !== ""){
                    let ShopId = shop.id.replace("tn-", "");
                    let rand = "revie-tn-widget";
                    codeHtml = `<section  id='${rand}' data-title='${shopPHP.data.config["widget_title"].value}' data-buttontext='${shopPHP.data.config["widget_buttontext"].value}' data-starcolor='${shopPHP.data.config["widget_star_color"].value}' data-starsize='${shopPHP.data.config["widget_font-size-start"].value}' data-maxpage='${shopPHP.data.config["widget_maxpagetestimonial"].value}' data-shopid='${ShopId}' data-type='${shopPHP.data.config["format"].value}' data-titlesize='${shopPHP.data.config["widget_title_font-size"].value}' data-titlecolor='${shopPHP.data.config["widget_title_color"].value}' data-titlepos='${shopPHP.data.config["widget_title_pos"].value}' data-maxwidth = '${shopPHP.data.config["widget_max-width"].value}' data-contentpadding = '${shopPHP.data.config["widget_padding"].value}' data-maxwidthimage = '${shopPHP.data.config["widget_maxwidthimage"].value}' data-sizetext = '${shopPHP.data.config["widget_text_font-size"].value}' data-textcolor = '${shopPHP.data.config["widget_text_color"].value}' data-backcolor = '${shopPHP.data.config["widget_background_color"].value}' data-widgetbuttontextproduct = '${shopPHP.data.config["widget_buttontextproduct"].value}' data-widgetimageproduct = '${shopPHP.data.config["widget_image_product"].value}' data-idproduct = ''></section><script>if(document.getElementById('revie') == null){let revie = document.createElement('script');revie.src = 'https://api.revie.lat/www/config/?store=0000&type=widget';revie.id = 'revie';revie.onload = function(){setTimeout(function(){inicRevie('${rand}')}, 100);};document.head.append(revie);}else{window.onload = function() {setTimeout(function(){inicRevie('${rand}')}, 100);};}</script>`;
                    codeHtml = codeHtml.replaceAll("'", "$#");
                }
                shopPHP.data.config[name].value = value; 
                shopPHP.data.config[name].data = codeHtml; 
                console.log(shopPHP);
                console.log(shopPHP.data.config);
                this.setState({shopPHP: shopPHP});
            }
        }else if(name === "GC"){
            this.generateCode();
        }else{
            const { shopPHP } = this.state;
            shopPHP.data.config[name].value = value; 
            console.log(shopPHP.data.config);
            this.setState({shopPHP: shopPHP});
        }
    }

    onUpDate = (name) => {
        const { shop } = this.state;
        Util.setInfoShop(this.handleOkShop, this.handleOkShoperror, shop);
    }

    handleOkShop = (data) => {
        if (data && data.id){
            localStorage.setItem(Constants.storage.USER_SHOP, JSON.stringify(data));
            Util.setAdminShop(data);
        }
    }

    handleOkShoperror = (data) => {
        console.log(data);
    }

    updateTheme = () => {
        const { shopPHP } = this.state;
        this.setState({loading: true});
        Util.updateConfig10(shopPHP,this.handleOkShopTheme20, this.handleOkShopErrorTheme20);
    }

    installTheme1 = () => {
        const { shop } = this.state;
        this.setState({loading: true});
        Util.installTheme20New(shop.lang, this.handleOkShopThemeInstall20, this.handleOkShopErrorTheme20);
    }

    handleOkShopThemeInstall20 = (data) => {
        
        this.setState({notify:Util.getMessage("TEXT_UPDATE_OK")});
        this.setState({loading: false});
        const { shop } = this.state;
        Util.getInfoShopAdminPHP(shop.lang, this.callbackPHP, this.callbackErrorPHP);
    }

    handleOkShopTheme20 = (data) => {
        const { shop, shopPHP } = this.state;
        this.setState({notify:Util.getMessage("TEXT_UPDATE_OK")});
        
        if(shopPHP.data.theme === "1"){
            Util.getJSStore();
        }else if(shopPHP.data.theme === "9"){
            Util.getJSStoreNew(shop.lang);
        }else if(Util.isTiendaNube(shop)){
            Util.getJSStoreTiendaNube(); 
        }
        
        
        this.setState({loading: false});
    }

    handleOkShopErrorTheme20 = (data) => {
        console.log(data);
    }

    onBlurTemplates = (selected, id) => {
        const { shop, templates } = this.state;
        if (templates[id].plan.hasOwnProperty(shop.plan.planType)){
            return false;
        }else{
            shop.template.name = id;
            this.setState({shop: shop});
            Util.setInfoShop(this.handleOkShop, this.handleOkShoperror, shop);
        }
    }  

    goToPedding = () => {
        this.props.dispatch({
            type: 'CHANGE_MENU',
            new_option: "/plan"
        });
    }

    exportData = () => {
        this.setState({isShowExport: true});
        Util.exportCVS(this.handleOkShopExport, this.handleOkShoperrorExport);
    }

    handleOkShopExport = (data) => {
        this.setState({isShowExport: true});
        console.log(data);
    }

    handleOkShoperrorExport = (data) => {
        console.log(data);
    }

    decodeHTML = (html) => {
        var txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    import = () => {
        const { addScript } = this.state;
        if(addScript){
            let scriptLoader = document.getElementById("script");
            if(!scriptLoader){
                let script = document.createElement("script");
                script.src = "https://tally.so/widgets/embed.js";
                script.id = "script";
                document.head.append(script);   
                this.setState({addScript: false});
            }
        }
        setTimeout(function(){
            let buttonImport = document.getElementById("import-external");
            buttonImport.click();
        }.bind(this), 400);
    }

    generateCode = () => {
        const { widgetCode } = this.state;
        let widgetCodeNew = !widgetCode;
        this.getWidgetHtml(widgetCodeNew);
        this.setState({widgetCode: widgetCodeNew});
        if(widgetCodeNew){
            this.updateTheme()
        }
    }

    closedGenerateCode = () => {
        this.setState({widgetCode: false});
    }

    getWidgetHtml = (code=false) => {
        setTimeout(function(){
            
            const { shopPHP, shop } = this.state;
            const isVtex = Util.isVtex(shop);
            
            if(shopPHP){
                let ShopId = isVtex ?  shop.id.replace("vtex-", "") : shop.id.replace("tn-", "");
                let rand = "revie-" + ShopId + "-" + Math.floor(Math.random() * 100000);
                let iframe;
                let codeHtml = `
<section 
    id="${rand}"
    data-title="${shopPHP.data.config["widget_title"].value}"
    data-buttontext="${shopPHP.data.config["widget_buttontext"].value}"
    data-starcolor="${shopPHP.data.config["widget_star_color"].value}"
    data-starsize="${shopPHP.data.config["widget_font-size-start"].value}"
    data-maxpage="${shopPHP.data.config["widget_maxpagetestimonial"].value}"
    data-shopid="${ShopId}"
    data-type="${shopPHP.data.config["format"].value}"
    data-titlesize="${shopPHP.data.config["widget_title_font-size"].value}"
    data-titlecolor="${shopPHP.data.config["widget_title_color"].value}"
    data-titlepos="${shopPHP.data.config["widget_title_pos"].value}"
    data-maxwidth = "${shopPHP.data.config["widget_max-width"].value}"
    data-contentpadding = "${shopPHP.data.config["widget_padding"].value}"
    data-maxwidthimage = "${shopPHP.data.config["widget_maxwidthimage"].value}"
    data-sizetext = "${shopPHP.data.config["widget_text_font-size"].value}"
    data-textcolor = "${shopPHP.data.config["widget_text_color"].value}"
    data-backcolor = "${shopPHP.data.config["widget_background_color"].value}"
    data-widgetbuttontextproduct = "${shopPHP.data.config["widget_buttontextproduct"].value}"
    data-widgetimageproduct = "${shopPHP.data.config["widget_image_product"].value}"
    data-idproduct = ""
></section>

<script>if(document.getElementById("revie") == null){let revie = document.createElement("script");revie.src = "https://api.revie.lat/www/config/?store=0000&type=${isVtex ? "widgetvtex" : "widget"}";revie.id = "revie";revie.onload = function(){setTimeout(function(){inicRevie("${rand}")}, 100);};document.head.append(revie);}else{window.onload = function() {setTimeout(function(){inicRevie("${rand}")}, 100);};}</script>`;
                if(code){
                    iframe = document.createElement("textarea");
                    iframe.id = "myInput";
                    iframe.innerHTML = codeHtml;
                }else{
                    iframe = document.createElement("iframe");
                    iframe.srcdoc = `<!DOCTYPE html>`+codeHtml;
                }
                
                let elemento = document.getElementById("idIframe");
                if(elemento){
                    elemento.innerHTML = "";
                    elemento.append(iframe);
                }
            }
          }.bind(this), 200);
    }

    copy = () => {
        var copyText = document.getElementById("myInput");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        
        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML =Util.getMessage("Copied");
      }
      
    outFunc  = () =>  {
        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = Util.getMessage("clipboard");
      }

    render() {
        const { templates, shop, configTN, storeTN, configTNWidgets, isShowExport, notify, shopPHP, loading, enabledPriceRules, totalDaysInstall, widgetCode, permissions } = this.state;
        return (
            <div className="content-config">
                {notify !== "" && (
                    <Notify text={notify} />
                )}
                <div className='title'>{Util.getMessage("option_conf")}</div>
                {/*
                <div className='content-flex'>
                    <ul className='list-element only' dangerouslySetInnerHTML={{__html: Util.getMessage("option_install")}} />
                </div>
                */}

                <div className='sub-title content-flex'><div className='number'>1</div>{Util.getMessage("option_conf_sub")}</div>
                <div className='text'>{Util.getMessage("option_conf_text")}</div>
                <div className='content-flex flex-direction-column-mobile'>
                    {shop && Object.keys(templates).map(
                        (template, index) => (
                            <div key={index} className='template-whatsapp'>
                                <CheckboxTemplate row={templates[template]} disabled={templates[template].plan.hasOwnProperty(shop.plan.planType)} template={shop.template} planActive={templates[template].planActive} onBlur={this.onBlurTemplates}/>
                            </div>
                    ))}
                </div>
                <div className='sub-title content-flex'><div className='number'>2</div>{Util.getMessage("option_conf_sub2")}</div>
                <div className='text'>
                    {Util.getMessage("option_conf_text1_lang")}
                    {shop && (
                        <SelectHtml options={Util.LANG} name="lang" value={shop.lang} onChange={this.onChange}/>
                    )}
                </div>
                <div className='text'>
                    {Util.getMessage("option_conf_text2")}
                    {shop && (
                        <SelectHtml disabled={shop.plan.planType === "0" && shop.shopType === "SHOPIFY"}  options={Util.DAYS} name="daysNotify" value={shop.daysNotify} onChange={this.onChange}/>
                    )}
                </div>
                {totalDaysInstall < 3 && (
                    <div className='text'>
                        {Util.getMessage("option_conf_text_install")}
                        {shop && (
                            <SelectHtml options={Util.DAYSORDERS} disabled={shop.plan.planType === "0" && shop.shopType === "SHOPIFY"} name="daysOrders" value={(shop.daysOrders ? shop.daysOrders : "")} onChange={this.onChange}/>
                        )}
                    </div>
                )}
                <hr className='internal'/>
                {shop && shop.plan && (
                    <>
                        <div className='text'>
                            {Util.getMessage("option_conf_reciveAudio")} {permissions["reciveAudio"].hasOwnProperty(shop.plan.planType) && (<div className='update-plan-name'> {Util.getMessage("PlanSupperLITE")} <a href="#" onClick={() => this.goToPedding()}>{Util.getMessage("update_plan")}</a></div>)}  
                            {shop && (
                                <>
                                    <SelectHtml disabled={permissions["reciveAudio"].hasOwnProperty(shop.plan.planType)} options={Util.yesNo} name="reciveAudio" value={(shop.reciveAudio)} onChange={this.onChangePhoto}/>
                                </>
                            )}
                            
                        </div>
                        <div className='text'>
                            {Util.getMessage("option_conf_reciveImages")} {permissions["reciveImages"].hasOwnProperty(shop.plan.planType) && (<div className='update-plan-name'> {Util.getMessage("PlanSupperLITE")} <a href="#" onClick={() => this.goToPedding()}>{Util.getMessage("update_plan")}</a></div>)}  
                            {shop && (
                                <>
                                    <SelectHtml disabled={permissions["reciveImages"].hasOwnProperty(shop.plan.planType)} options={Util.yesNo} name="reciveImages" value={(shop.reciveImages)} onChange={this.onChangePhoto}/>
                                </>
                            )}
                            
                        </div>
                        <div className='text'>
                            {Util.getMessage("option_conf_reciveVideos")} {permissions["reciveVideos"].hasOwnProperty(shop.plan.planType) && (<div className='update-plan-name'> {Util.getMessage("PlanSupperLITE")} <a href="#" onClick={() => this.goToPedding()}>{Util.getMessage("update_plan")}</a></div>)}  
                            {shop && (
                                <>
                                    <SelectHtml disabled={permissions["reciveVideos"].hasOwnProperty(shop.plan.planType)} options={Util.yesNo} name="reciveVideos" value={(shop.reciveVideos)} onChange={this.onChangePhoto}/>
                                </>
                            )}
                        </div>
                        <div className='text'>
                            {Util.getMessage("option_conf_discount")} {permissions["discount"].hasOwnProperty(shop.plan.planType) && (<div className='update-plan-name'> {Util.getMessage("PlanSupperPRO")} <a href="#" onClick={() => this.goToPedding()}>{Util.getMessage("update_plan")}</a></div>)}  
                            {shop && (
                                <>
                                    <SelectHtml disabled={permissions["discount"].hasOwnProperty(shop.plan.planType) || (!enabledPriceRules && !(shop.reciveImages || shop.reciveVideos))} options={Util.yesNo} name="active" value={(shop.discount.active)} onChange={this.onChangeDiscount}/>
                                </>
                            )}
                        </div>
                        {!enabledPriceRules && (
                            <div className='text'>
                                <b>{Util.getMessage("TEXT_ENABLED_DISCOUNT")} <a href={window.url_server_2+"view/update/?shop="+Util.getMetafieldMyshopify_domain()}>LINK</a></b>
                            </div>
                        )}
                        {shop.discount && shop.discount.active && (
                            <>
                                <div className='text'>
                                    {Util.getMessage("option_conf_discount_type")} 
                                    {shop && (
                                        <>
                                            <SelectHtml disabled={(permissions["discount"].hasOwnProperty(shop.plan.planType) || !(shop.reciveImages || shop.reciveVideos))} options={Util.valueType} name="valueType" value={(shop.discount.valueType)} onChange={this.onChangeDiscount}/>
                                        </>
                                    )}
                                </div>
                                <div className='text'>
                                    {Util.getMessage("option_conf_discount_value"+shop.discount.valueType)}
                                    {shop && (
                                        <>
                                            <InputHtml className="min-input" disabled={(permissions["discount"].hasOwnProperty(shop.plan.planType) || !(shop.reciveImages || shop.reciveVideos))} edit={true} name="value" value={(shop.discount.value)} onBlur={this.onBlurDiscount} update={this.onChangeDiscount}/>
                                        </>
                                    )}
                                    
                                </div>
                            </>
                        )}
                    <hr className='internal'/>
                    <div className='text'>
                        {Util.getMessage("option_conf_windowDaysReviews")} {permissions["windowDaysReviews"].hasOwnProperty(shop.plan.planType) && (<div className='update-plan-name'> {Util.getMessage("PlanSupperPRO")} <a href="#" onClick={() => this.goToPedding()}>{Util.getMessage("update_plan")}</a></div>)}  
                        {shop && (
                            <>
                                <SelectHtml disabled={permissions["windowDaysReviews"].hasOwnProperty(shop.plan.planType)} options={Util.DAYS_REQUEST} name="windowDaysReviews" value={(shop.windowDaysReviews)} onChange={this.onChange}/>
                            </>
                        )}
                        
                    </div>
                    <div className='text'>
                        {Util.getMessage("option_conf_validateProductReviews")} {permissions["windowDaysReviews"].hasOwnProperty(shop.plan.planType) && (<div className='update-plan-name'> {Util.getMessage("PlanSupperPRO")} <a href="#" onClick={() => this.goToPedding()}>{Util.getMessage("update_plan")}</a></div>)}  
                        {shop && (
                            <>
                                <SelectHtml disabled={permissions["windowDaysReviews"].hasOwnProperty(shop.plan.planType)} options={Util.yesNoForwardReview} name="validateProductReviews" value={(shop.validateProductReviews)} onChange={this.onChange}/>
                            </>
                        )}
                        
                    </div>
            
                    <hr className='internal'/>
                    <div className='text'>
                        <div>
                            <label className="form-check-conteiner form-check-config">
                                <input type="checkbox" name="autoPublishStar" id="autoPublishStar" onChange={() => this.onChangeStar()} 
                                    value="autoPublishStar" checked={!!shop.autoPublishStar} disabled={permissions["autoPublishStar"].hasOwnProperty(shop.plan.planType)}
                                /> 
                                <span className={`form-check-span`}></span>
                                <div className="name">{Util.getMessage("option_conf_text_ap")} {permissions["autoPublishStar"].hasOwnProperty(shop.plan.planType) && (<div className='update-plan-name'> {Util.getMessage("PlanSupperPRO")} <a href="#" onClick={() => this.goToPedding()}>{Util.getMessage("update_plan")}</a></div>)}</div>
                            </label>
                        </div>
                        <div className={`content-flex ${!shop.autoPublishStar ? 'disabled' : ''}`}>
                            {Util.getMessage("option_conf_text_ap_inc")}
                            <div className='star-select-config'>
                                {!!shop.autoPublishStar && (<SelectStar start={shop.autoPublishStar} onChange={this.onChangeStarNumber} isText={false} titleHover='option_conf_text_ap_fintooltip'/>)}
                            </div>
                            {Util.getMessage("option_conf_text_ap_fin")}
                        </div>
                    </div>
                
                
                <div className='text'>
                    {Util.getMessage("option_conf_notifyEmail")}
                    <SelectHtml options={Util.yesNo} name="notifyEmail" value={(shop.notifyEmail)} onChange={this.onChange}/>
                </div>
                {shop && (shop.notifyEmail === "true" || shop.notifyEmail === true ) && (
                    <div className='text'>
                        {Util.getMessage("option_conf_nEmail")}
                        {shop && (
                            <>
                                <InputHtml edit={true} name="email" value={(shop.email)} onBlur={this.onBlur} update={this.onUpDate}/>
                            </>
                        )}
                        
                    </div>
                )}
                <div className='text'>
                    {Util.getMessage("option_conf_import")} {permissions["import"].hasOwnProperty(shop.plan.planType) && (<div className='update-plan-name'> {Util.getMessage("PlanSupperLITE")} <a href="#" onClick={() => this.goToPedding()}>{Util.getMessage("update_plan")}</a></div>)} 
                    <div><Button disabled={permissions["import"].hasOwnProperty(shop.plan.planType)} value={Util.getMessage("import")} type="primary" onClick={() => this.import()}/></div>
                </div>
                <button style={{display: "none"}} id="import-external" data-tally-open="wgdkxD" data-tally-width="378" data-tally-overlay="1" data-tally-emoji-text="👋" data-tally-emoji-animation="wave" data-tally-auto-close="1000"></button>

                <div className='text'>
                    {Util.getMessage("option_conf_Export")}
                    {shop && (
                        <div>
                            <Button disabled={isShowExport} value={Util.getMessage("export")} type="primary" onClick={() => this.exportData()}/>
                        </div>
                    )}
                    
                </div>
                {isShowExport && (
                    <div className='text success'>{Util.getMessage("option_conf_Export_exit")} {shop.email}</div>
                )}
                <div className='text'>
                    {Util.getMessage("option_conf_googleFeed")} {(Util.isGoogleFeed(shop)) && (<div className='update-plan-name'> {Util.getMessage("PlanSupperPRO")} <a href="#" onClick={() => this.goToPedding()}>{Util.getMessage("update_plan")}</a></div>)} 
                    <SelectHtml disabled={(Util.isGoogleFeed(shop))} options={Util.yesNo} name="createGoogleFeed" value={(shop.createGoogleFeed)} onChange={this.onChange1000}/>
                </div>
                {shop.createGoogleFeed && (
                    <div className='text'>{Util.getMessage("option_conf_googleFeed_exit")} <b>{`https://revie-prod-feed.s3.amazonaws.com/${shop.id}/google-product-review-feed-${shop.id}.xml`}</b></div>
                )}
            </>
        )}
                {/*}
                <div className='sub-title'>{Util.getMessage("option_conf_sub3")}</div>
                {(configView).map(
                    (config, index) => (
                        <div key={index}>
                            <CheckboxText 
                                textActive={config.textActive} 
                                textInActive={config.textInActive}
                                text={config.text}
                                selected={config.selected}
                            />
                        </div>
                    ))}*/}

                {shop && Util.isShopify(shop) && (shopPHP && shopPHP.data && shopPHP.data.theme === "2" ) && (
                    <>
                        <div className='sub-title content-flex' id="instructions"><div className='number'>3</div>{Util.getMessage("opt_review_title")}</div>
                        {/*<div className='text error' dangerouslySetInnerHTML={{__html: Util.getMessage("opt_help")}} />
                        <div className='text'>{Util.getMessage("opt_review_sub_title")}</div>
                        <div className='text' dangerouslySetInnerHTML={{__html: Util.getMessage("opt_review_sub_title_1")}} />
                        <img src='/img/rating.png' className='img-conf' style={{maxWidth: "20rem"}} />
                        <div className='content-flex'>
                            <div className="video">
                                <iframe src="https://player.vimeo.com/video/743303399?h=306da22f20" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                            </div>
                            <ul className='list-element' dangerouslySetInnerHTML={{__html: Util.getMessage("opt_review_sub_title_2")}} />
                        </div>
                        <div className='text' dangerouslySetInnerHTML={{__html: Util.getMessage("opt_review_sub_title_3")}} />
                        <img src='/img/widget.png' className='img-conf' style={{maxWidth: "50rem"}} />
                        <div className='content-flex'>
                            <div className="video">
                                <iframe src="https://player.vimeo.com/video/743303399?h=306da22f20" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                            </div>
                            <ul className='list-element' dangerouslySetInnerHTML={{__html: Util.getMessage("opt_review_sub_title_4")}} />
                        </div>
                        
                        <hr className='conf'/>
                        {false && (
                            <>
                            <div className='sub-title content-flex'><div className='number'>4</div>{Util.getMessage("opt_minus_2_0_title")}</div>
                            <div className='text'>{Util.getMessage("opt_minus_2_0_sub_title")}</div>
                            <div className='content-flex'>
                                <div className="image-conf">
                                    <img src='img/conf20_1-min.jpg'/>
                                </div>
                                <ul className='list-element' dangerouslySetInnerHTML={{__html: Util.getMessage("opt_minus_2_0_text_1")}} />
                            </div>
                            <div className='text'>{Util.getMessage("opt_minus_2_0_sub_title_1")}</div>
                            <div className='text'>
                                <Button value="INSTALAR" type="primary" onClick={() => this.install()}/>
                            </div>
                            </>
                        )}/*/}

                        <ul className='list-element list-special'>
                            <li dangerouslySetInnerHTML={{__html: Util.getMessage("opt_review_sub_title_new_1")}} />
                            <li>
                                <div dangerouslySetInnerHTML={{__html: Util.getMessage("opt_review_sub_title_new_2")}} />
                                <div style={{"marginTop": "1rem"}}>
                                    <Button value={Util.getMessage("INSTALAR_theme1")} type="primary" onClick={() => this.installTheme1()}/>
                                </div>
                            </li>
                            <li dangerouslySetInnerHTML={{__html: Util.getMessage("opt_review_sub_title_new_3")}} />
                        </ul>
                    </>
                )}
                {(shopPHP && shopPHP.data && shopPHP.data.config && (shopPHP.data.theme === "9" || shopPHP.data.theme === "1" || (shop && Util.isTiendaNube(shop) || Util.isVtex(shop))))&& (
                    <>
                    {!Util.isVtex(shop) ? 
                        <>
                            <div className='sub-title content-flex' id="instructions"><div className='number'>3</div>{Util.getMessage("opt_review_title")}</div>
                            <div className='text'>{Util.getMessage("opt_minus_2_0_sub_title_2")}</div>
                            <div style={{"maxWidth": "500px"}}>
                                {Object.keys(shopPHP.data.config).map(
                                    (config, index) => (
                                        <div key={index}>
                                            {shopPHP.data.config[config].view || shopPHP.data.config[config].type === "select" ? (
                                                <div className='text'>
                                                    {shopPHP.data.config[config].type === "text" ? Util.getMessage("TEXT_CONFIG") : shopPHP.data.config[config].label[shop.lang]}
                                                    {shop && (
                                                        <>
                                                            {shopPHP.data.config[config].type === "select" ? (
                                                                <SelectHtml edit={true} name={config} value={shopPHP.data.config[config].value} options={shopPHP.data.config[config].options} onChange={this.onUpConf} />
                                                            ) : (
                                                                <InputHtml unit={shopPHP.data.config[config].unit} type={shopPHP.data.config[config].type} min={shopPHP.data.config[config].min} max={shopPHP.data.config[config].max} edit={true} name={config} value={shopPHP.data.config[config].value ?  shopPHP.data.config[config].value : this.decodeHTML(shopPHP.data.config[config].label[shop.lang])} onBlur={this.onUpConf}/>
                                                            )}
                                                        </>
                                                    )}
                                                    
                                                </div>
                                            ) :(<div></div>)}
                                        </div>
                                ))}
                            </div>
                            {Util.isTiendaNube(shop) ? (
                                <div className='text'>
                                    {Util.getMessage("title_option_position")}
                                    <Tabs tabs={configTN} value={shopPHP.data.config} onChange={this.onUpConf} theme={(storeTN && storeTN.current_theme) ? storeTN.current_theme : "null" }/>
                                </div>
                                ) : null}
                            <div className='text'>
                                <Button value={Util.getMessage("SAVE")} disabled={loading} type="primary" onClick={() => this.updateTheme()}/>
                            </div>
                        </> : null}

                    {(shop && (Util.isTiendaNube(shop)) || Util.isVtex(shop)) && (
                        <>
                            <div className='sub-title content-flex' id="instructions"><div className='number'>{Util.isVtex(shop) ? "3" : "4"}</div>{Util.getMessage("opt_widget_title")}</div>
                            <div className='text'>{Util.getMessage("opt_widget_subtitle")}</div>
                            <div className={`content-flex content-widget content-widget-tn ${widgetCode ? "edit-code" : ""}`}>
                            <div className='content-inic-widget'>
                                {widgetCode ? (
                                    <>
                                        <div dangerouslySetInnerHTML={{__html: Util.getMessage("message-help-widget")}} />
                                        <div className='content-flex'>
                                            <div className="context-tooltip">
                                                <div className="tooltip">
                                                    <button className="button quarter" onClick={() => this.copy()} onMouseOut={() => this.outFunc()} >
                                                        <span className="tooltiptext" id="myTooltip">{Util.getMessage("clipboard")}</span>
                                                        {Util.getMessage("Copy-text")}
                                                    </button>
                                                </div>
                                            </div>
                                            <Button value={Util.getMessage("CLOSE_CODE")} type="primary" onClick={() => this.closedGenerateCode()}/>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {Object.keys(shopPHP.data.config).map(
                                            (config, index) => (
                                                <div key={index}>
                                                    {shopPHP.data.config[config].widget ? (
                                                        <div className='text'>
                                                            {shopPHP.data.config[config].label[shop.lang]}
                                                            {shop && (
                                                                <>
                                                                    {shopPHP.data.config[config].type === "select-widget" ? (
                                                                        <SelectHtml edit={true} name={config} value={shopPHP.data.config[config].value} options={shopPHP.data.config[config].options} onChange={this.onUpConf} />
                                                                    ) : (
                                                                        <InputHtml unit={shopPHP.data.config[config].unit} type={shopPHP.data.config[config].type} min={shopPHP.data.config[config].min} max={shopPHP.data.config[config].max} edit={true} name={config} value={shopPHP.data.config[config].value ?  shopPHP.data.config[config].value : this.decodeHTML(shopPHP.data.config[config].label[shop.lang])} onBlur={this.onUpConf}/>
                                                                    )}
                                                                    {shopPHP.data.config[config].info && (
                                                                        <div className='help-aut'>{Util.getMessage(shopPHP.data.config[config].info)}</div>
                                                                    )}
                                                                </>
                                                            )}
                                                            
                                                        </div>
                                                    ) :(<div></div>)}
                                                </div>
                                        ))}

                                    </>
                                )}
                            </div>
                                <div id="idIframe"></div> {this.getWidgetHtml(widgetCode)}
                            </div>
                            <div className='text'>
                                {Util.getMessage("title_option_position")}
                                <Tabs tabs={configTNWidgets} value={shopPHP.data.config} onChange={this.onUpConf} theme={(storeTN && storeTN.current_theme) ? storeTN.current_theme : "null" }/>
                            </div>
                            <div className='text' style={{marginBottom: "7rem"}}>
                                <Button value={Util.getMessage("SAVE")} disabled={loading} type="primary" onClick={() => this.updateTheme()}/>
                            </div>
                        </>
                    )}
                    </>
                )}
                </div>
            );
        }
    }

    
const mapStateToProps = (state) => ({
    packageList: state.packageList,
    shopState: state.shop,
    user: state.user,
    ordens: state.ordens,
    shippings: state.shippings,
    config: state.config
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Config);